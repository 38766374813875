.window{
  background-color: #282c34;
  width:100%;
}

.App {
  background-color: #282c34;
}

.App-logo {
  margin-right:5vh;
  pointer-events: none;
  height:17vh;
}

.App-content {
  margin-left:3vh;
  margin-right:3vh;
  background-color: #282c34;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  color: white;
}

.md-auto{
  padding-left:2vmin;
}

.timer-container{
  display: inline;
}

.timer-chunk{
  padding:.5vh;
  background-color:grey;
}
.timer-padding{
  width:.2vh;
}

.leaflet-container{
  height:100%;
  width:100%;
}

.leaflet-grid-label .lng {
  margin-left: 8px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.FoH-Header {
  background-color: #202526;
  color: white;
}

.FoH-Nav-Link {
  border-color: 1px white;
  color: white;
  transition:0.3s;
  font-size: 3vh;/*calc(10px + 1vmin);*/
}

.FoH-Nav-Link:hover {
  transition:0.3s;
  background-color:dimgrey;
}

.FoH-Nav-Link.active {
  background-color:dimgrey;
}

.FoH-Nav-Item{
  padding-left: 1vh;
  text-align: right;
}

.FoH-Nav-Toggler{
  color:white;
}

.App-link {
  color: #61dafb;
}

.App-content{
  padding-top:3vh;
}

.FoH-Tile{
  padding: 1vh;
}

.FoH-Tile-Container{
  display: inline-block;
}

.FoH-Header-Text{
  background-color: #343240;
  text-align:center;
  padding:3vh;
  max-width: 80vh;
}

.button{
  padding-top:0.5vh;
  padding-bottom:0.5vh;
  padding-left:1vh;
  padding-right:1vh;
  background: #4E9CAF;
  text-align: center;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

.navbar-collapse{
  padding-top:10vmin;
}

.progtrckr{
  text-align: center;
}

.progtrckr-done{
  color:white!important;
}

.progtrckr-doing{
  color:white!important;
}

.divider{
  height: 1px;
  width: 95%;
  margin-top:3vh;
  margin-bottom:3vh;
  background-color:grey;
}

.footer-buttons{
  padding-top:3vmin;
}

.divider-no-margin{
  margin-top:1.5vh;
  height: 1px;
  width: 95%;
  background-color:grey;
}

.FoH-Foot-Text{
  font-size: calc(4px + 1vmin);
}

.FoH-Foot{
  padding:1vh;
  text-align: center;
}

.FoH-Tile-Small-Text{
  word-wrap: normal;
  font-size: calc(4px + 2vmin);
  width: 30vh;
}

.presenters{
  text-align:center;
}

.Sponsor-Image{
  padding:1vh;
  width:100%;
}

.FoH-Volunteer-Subtext{
  font-size: calc(1px + 2vmin);
}

.FoH-Volunteer-Opportunities{
  padding-left:2vh;
  padding-right:2vh;
  width: 40vh;
}

.FoH-Volunteer-Description{
  padding-left:2vh;
  padding-right:2vh;
}

.FoH-Volunteer-List{
  padding-bottom:1vh;
  font-size: calc(2px + 2vmin);
}

.FoH-Volunteer-Op-Title{
  font-size: calc(4px + 2vmin);
}

.Vertical-Line{
  width: 1px;
  background-color:white;
}

.FoH-Race-Very-Small{
  font-size: calc(1px + 1vmin);
}

.formTextWhite{
  color:white;
  background-color:#222528;
  padding-left:2vh;
  padding-right:2vh;
  padding-top:1vh;
  padding-bottom:1vh;
  border:2px solid white;
}

.purchaseInput{
  color:black;
}

.delete-button{
  margin-left:3vh;
}

.invalid-border{
  border:2px solid red!important;
}

/* Blue Tag example*/
.tag-tag{
  margin-top:5vh;
  margin-left:3vh;
  background-color: lightblue;
  /*width:900px;*/
  width:60vh;
  /*height:540px;*/
  height:36vh;
  border-radius:10px;
}

.tag-header{
  text-align: center;
  padding-top: 1vh;
  margin-bottom: 1vh;
}

.tag-body{
  padding-right:1vh;
  padding-left:1vh;
  padding-bottom:1vh;
  height:30vh;
}

.tag-inner{
  border: 2px solid black;
  height: 100%;
  padding:1vh;
}

.tag-icon{
  width:7vh;
  height:7vh;
}

.noErr{
  display:none;
}

.err{
  display:inline;
}

.tag-circle {
  width:3vh;
  height:3vh;
}

.tag-heroname{
  height:2.5vh;
  font-size: 2.25vh;
  color:black;
  font-weight: bold;
}

.tag-heroother{
  height:2.25vh;
  font-size: 2vh;
  color:black;
  font-weight: bold;
}

.tag-tribute{
  height:2.25vh;
  font-size: 2vh;
  color:black;
  height:20vh;
}

.tag-herocode{
  font-size: 2vh;
  color:black;
}

.tag-presenter{
  font-size: 2vh;
  color:black;
}

.tag-footer{
  display:inline;
  margin:1vh;
}

.tag-bottom-right{
  text-align:right;
}

/*End Blue Tag example*/

/* Blue Tag example*/
.new-tag-tag{
  margin-top:5vmin;
  margin-left:3vmin;
  background-color: lightblue;
  width:60vmin;
  height:36vmin;
  border-radius:1vmin;
}

.new-tag-header{
  text-align: center;
  padding-top: 1vmin;
  margin-bottom: 1vmin;
}

.new-tag-body{
  padding-right:1vmin;
  padding-left:1vmin;
  padding-bottom:1vmin;
  height:30vmin;
}

.new-tag-inner{
  border: .15vmin solid black;
  height: 100%;
  padding:1vmin;
}

.new-tag-icon{
  width:7vmin;
  height:7vmin;
}

.new-tag-circle {
  width:3vmin;
  height:3vmin;
}

.new-tag-heroname{
  height:2.5vmin;
  font-size: 2.25vmin;
  color:black;
  font-weight: bold;
}

.new-tag-heroother{
  height:2.25vmin;
  font-size: 2vmin;
  color:black;
  font-weight: bold;
}

.new-tag-tribute{
  height:2.25vmin;
  font-size: 2vmin;
  color:black;
  height:20vmin;
  white-space:pre-wrap;
}

.new-tag-herocode{
  font-size: 2vmin;
  color:black;
}

.new-tag-presenter{
  font-size: 2vmin;
  color:black;
}

.new-tag-footer{
  display:inline;
  margin:1vmin;
}

.new-tag-bottom-right{
  text-align:right;
}

/*End Blue Tag example*/